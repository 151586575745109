// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import * as expiration from "workbox-expiration";
import * as precaching from "workbox-precaching";
import * as routing from "workbox-routing";
import * as strategies from "workbox-strategies";

/**
 * Cache content
 */
routing.registerRoute(
    /(?:(.*)\.htm)?$/,
    new strategies.NetworkFirst({
        cacheName: "dunwell-content-cache",
        plugins: [
            new expiration.Plugin({
                maxAgeSeconds: 7 * 24 * 60 * 60,
            }),
        ],
    })
);

/**
 * Cache CSS files
 */
routing.registerRoute(
    /\.css$/,
    new strategies.CacheFirst({
        cacheName: "dunwell-css-cache",
    })
);

/**
 * Cache JS files
 */
routing.registerRoute(
    /\.js$/,
    new strategies.CacheFirst({
        cacheName: "dunwell-js-cache",
    })
);

/**
 * Cache image files
 */
routing.registerRoute(
    /\.(gif|jpeg|jpg|png|svg|webp)$/,
    new strategies.CacheFirst({
        cacheName: "dunwell-image-cache",
        plugins: [
            new expiration.Plugin({
                maxAgeSeconds: 7 * 24 * 60 * 60,
            }),
        ],
    })
);

/**
 * Cache font files
 */
routing.registerRoute(
    /\.(otf|ttf|woff|woff2)$/,
    new strategies.CacheFirst({
        cacheName: "dunwell-font-cache",
        plugins: [
            new expiration.Plugin({
                maxAgeSeconds: 7 * 24 * 60 * 60,
            }),
        ],
    })
);

/**
 * Precache "offline" page
 */
precaching.precacheAndRoute([
    "/offline.htm",
]);

/**
 * Return "offline" page when visiting pages offlien that haven't been cached
 */
routing.setCatchHandler(() => {
    return caches.match("/offline.htm");
});
